/*********************************
  Stylesheet
  Web design: Peter Grondelaers
  Original date: 2021-06-15
  Last update: 2022-01-20
  ********************************/

/********************
  Table of contents:
  1. Global
  2. Utility classes
  3. Common
  4. CoinSummaryPage
    4.1 AddCoin
    4.2 CoinList
  5. CoinDetailPage
    5.1 HistoryChart
    5.2 CoinData
  *******************/

/********************
  1. Global
  *******************/
:root {
  --clr-primary-100: hsl(0, 0%, 95%); /* hsl(220, 3%, 83%); */
  --clr-primary-200: hsl(0, 0%, 90%);
  --clr-primary-300: hsl(0, 0%, 46%);
  --clr-primary-400: hsl(214, 14%, 22%);
  --clr-primary-700: hsl(208, 21%, 14%);
  --clr-fail: hsl(1, 81%, 59%);
  --clr-success: hsl(152, 53%, 40%);
  --clr-accent: hsl(210, 100%, 45%); /* also change in HistoryChart.jsx */
  --ff-primary: 'Open Sans', sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: var(--clr-primary-700);
  color: var(--clr-primary-100);
  font-family: var(--ff-primary);
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/********************
  2. Utility classes
  *******************/
.area {
  background-color: var(--clr-primary-200);
  border-radius: .5em;
  color: var(--clr-primary-700);
  padding: 1em;
}

.area:not(:last-of-type) {
  margin-bottom: 1em;
}

.btn {
  background-color: var(--clr-primary-300);
  border: none;
  border-radius: .25em;
  color: #FFF;
  cursor: pointer;
  font-weight: 700;
  padding: .5em .5em;
}

.btn:hover,
.btn:focus-visible,
.btn.active {
  background-color: var(--clr-accent);
}

.text--fail {
  color: var(--clr-fail);
}

.text--success {
  color: var(--clr-success);
}

/********************
  3. Common
  *******************/
h1 {
  color: #FFD700;
  margin-block: .5rem;
  text-align: center;
}

.container {
  margin-bottom: 1em;
  margin-inline: auto;
  width: 95%;
}

@media screen and (min-width: 40.01em) {
  h1 {
    margin-block: revert;
  }

  .container {
    width: min(80%, 72em);
  }
}

.arrow {
  margin-right: .25em;
}

/********************
  4. CoinSummaryPage
  4.1 AddCoin
  *******************/
.buttons {
  display: flex;
  gap: 0.5em;
  margin-bottom: .5em;
  position: relative;
}

.dropdown__toggle {
  padding-right: 1em;
}

.dropdown__toggle::after {
  border-top: .375em solid #FFF;
  border-right: .375em solid transparent;
  border-left: .375em solid transparent;
  content: '';
  position: relative;
  left: .5em;
  top: .75em;
  width: 0;
  height: 0;
}

.dropdown__menu {
  background-color: var(--clr-primary-100);
  border: 1px solid rgba(0,0,0,.2);
  display: none;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: repeat(12, auto);
  grid-auto-flow: column;
  position: absolute;
  top: 1.75em;
}

.dropdown__menu.show {
  display: grid;
}

.dropdown__item {
  color: var(--clr-primary-900);
  padding: .5em 1em;
  text-decoration: initial;
}

.dropdown__item:hover,
.dropdown__item:focus {
  background-color: rgba(0,0,0,.2);
}

/********************
  4.2 CoinList
  *******************/
.coin-list {
  display: flex;
  flex-direction: column;
  gap: .5em;
}

.coin-list__link {
  align-items: center;
  background-color: var(--clr-primary-100);
  border-bottom: 1px solid rgba(0,0,0,.2);
  border-top: 1px solid rgba(0,0,0,.2);
  color: initial;
  display: grid;
  gap: 0 .5em;
  grid-template-areas: "image price percentage close"
                       "name  price percentage close";
  grid-template-rows: auto auto;
  grid-template-columns: 3em 1fr auto auto;
  justify-items: center;
  padding: 1em;
  text-decoration: initial;
}

@media screen and (min-width: 40.01em) {
  .coin-list__link {
    gap: 1em;
    grid-template-areas: "image name price percentage close";
    grid-template-rows: auto;
    grid-template-columns: auto 1fr 1fr auto auto;
    justify-items: start;
  }
}

.coin-list__link:hover,
.coin-list__link:focus {
  background-color: rgba(0,0,0,.2);
}

.coin-list__image {
  grid-area: image;
  width: 3em;
  height: auto;
}

.coin-list__name {
  grid-area: name;
  font-size: .75rem;
  text-align: center;
}

@media screen and (min-width: 40.01em) {
  .coin-list__name {
    font-size: 1rem;
    text-align: left;
  }
}

@media screen and (max-width: 40em) {
  .coin-list__symbol {
    display: none;
  }
}

.coin-list__price {
  grid-area: price;
}

.coin-list__percentage {
  grid-area: percentage;
}

.coin-list__delete {
  color: var(--clr-primary-400);
  grid-area: close;
  padding: .5em;
  visibility: hidden;
}

@media screen and (max-width: 40em) {
  .coin-list__delete {
    visibility: visible;
  }
}

.coin-list__link:hover .coin-list__delete,
.coin-list__link:focus .coin-list__delete {
  visibility: visible;
}

.coin-list__delete:hover,
.coin-list__delete:focus {
  color: var(--clr-fail);
}

/********************
  5. CoinDetailPage
  5.1 HistoryChart
  *******************/
/* height doesn't change on window change, only checked on page load */
#myChart {
  height: 420px;
}

@media screen and (min-width: 40.01em) {
  #myChart {
    height: 600px;
  }
}

.chart__footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 1em;
}

.chart__data {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.chart__button-group {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: .5em;
  justify-content: flex-end;
}

/********************
  5.2 CoinData
  *******************/
.data {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0 1em;
}

.data__item {
  display: flex;
  flex-direction: column;
  gap: .5em;
  padding: 1em;
}

.data__title {
  font-size: .75rem;
  font-weight: 700;
}

@media screen and (max-width: 40em) {
  .data__item:nth-child(-n+5) {
    border-bottom: 1px solid rgba(0, 0, 0, .3);
  }
}

@media screen and (min-width: 40.01em) {
  .data {
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .data__item:nth-child(2n+1) {
    border-bottom: 1px solid rgba(0, 0, 0, .3);
  }
}